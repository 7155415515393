import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser } from '@typesApp/teaser'
import { ImageCrops } from '../CmsMedia/types'
import GenericBanner from '../GenericBanner'
import styles from './styles/index.module.scss'

type TLandscapeBanner = {
  placement: IPlacement | ICMCollection
  customerSegments: string[]
  teaserIndex?: number
  bannerIndex?: number
  lazy?: boolean
}

export const imageCrops: ImageCrops = {
  xxl: {
    crop: 'landscape_ratio40x13',
    width: 2880,
    height: 936,
  },
  xl: {
    crop: 'landscape_ratio40x13',
    width: 2560,
    height: 936,
  },
  lg: {
    crop: 'landscape_ratio32x13',
    width: 1843,
    height: 749,
  },
  md: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
  sm: {
    crop: 'landscape_ratio375x284',
    width: 675,
    height: 511,
  },
}

const LandscapeBanner: React.FC<TLandscapeBanner> = ({
  placement,
  customerSegments,
  teaserIndex,
  bannerIndex,
  lazy,
}) => {
  if (!placement) return null

  const item = 'items' in placement ? placement?.items?.find(isLXTeaser) : placement?.teasableItems?.find(isLXTeaser)

  return (
    <GenericBanner
      teaser={item}
      stylesViewType={styles['landscape-banner']}
      imageCrops={imageCrops}
      customerSegments={customerSegments}
      teaserIndex={teaserIndex}
      bannerIndex={bannerIndex}
      lazy={lazy}
    />
  )
}

export default LandscapeBanner
