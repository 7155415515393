import React, { useState } from 'react'
import { ITeaserOverlayStyle, ITermsAndConditions } from '@typesApp/cmsPlacement/LXTeaser'
import { CloseIcon } from '@components/UI/Icons/close'
import clsx from 'clsx'
import { TThemeVariant } from './types'
import styles from './styles/index.module.scss'
import { InfoOutlinedIcon } from '@components/UI/Icons/info'
import { useTranslation } from 'next-i18next'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

type TTermsAndCondition = {
  targetsTermsAndConditions?: ITermsAndConditions
  teaserOverlay2Style?: ITeaserOverlayStyle
}

export const TermsAndConditions: React.FC<TTermsAndCondition> = ({
  targetsTermsAndConditions,
  teaserOverlay2Style,
}) => {
  const { target, style = '', text: buttonLabel = '' } = targetsTermsAndConditions || {}
  const variant = (style?.split('-').pop() || 'primary') as TThemeVariant
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()

  if (!target) return null

  const { title, detailText } = target
  return (
    <div className={clsx('terms-conditions', styles['terms-conditions'])}>
      <div className={clsx('terms-conditions-cta-container', styles['terms-conditions-cta-container'])}>
        <button
          className={clsx(styles['terms-conditions-cta'], teaserOverlay2Style || 'default-color')}
          onClick={() => setIsOpen(true)}
          aria-label={t('TermsAndConditions.AriaLabels.OpenCta')}
          data-element-id="X_X_TermsAndConditions"
        >
          <InfoOutlinedIcon />
          {buttonLabel}
        </button>
      </div>
      <div className={clsx('terms-conditions-panel', styles['terms-conditions-panel'])}>
        <div
          className={clsx(styles['terms-conditions-panel-wrapper'], variant, {
            'is-open': isOpen,
          })}
        >
          <div className={clsx(styles['terms-conditions-header'])}>
            <h4 className={clsx(styles['terms-conditions-title'], variant)}>{title}</h4>
            <button className={styles['terms-conditions-close']} onClick={() => setIsOpen(false)}>
              <CloseIcon width={16} height={16} />
            </button>
          </div>
          {!!detailText && <div dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(detailText) }}></div>}
        </div>
      </div>
    </div>
  )
}
